import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update,push,child,get, set} from "firebase/database";
import {app} from "./api.js";
import {useNavigate} from 'react-router-dom'
import FileUp from './FileUp.js';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const db = getDatabase(app);
const dbRef = ref(getDatabase(app));
const auth = getAuth(app);

const BuyEdit = () => {
    const [id, setid] = useState(null);
    const {key} = useParams(); 

    const [pdata, setpdata] = useState([]);
    const [selldata, setselldata] = useState([]);
    const [sdata, setsdata] = useState([]);

  const [AUser, setAUser] = useState("");

  const [VendorData, setVendorData] = useState([])


    const navi = useNavigate();
    useEffect(() => {
      onAuthStateChanged(auth, user =>{
        if(user){

          get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
            if (snapshot.exists()) {
              const check = snapshot.val()
              if (check.status==="false") {
                navi('/wait')
              } else {
                setshowme(true)
              }
            } else {
              console.log("No data available");
              navi('/')
            }
          }).catch((error) => {
            console.error(error);
            navi('/')
          });

        }else{
          navi('/')
        }
      });
    }, [])

    const [data, setdata] = useState('')
    useEffect(() => {

        onAuthStateChanged(auth, user =>{
          if(user){

            get(child(dbRef, `buy/`)).then((snapshot) => {
                if (snapshot.exists()) {
                    console.log(key)
                    const fdata = Object.values(snapshot.val())
                    const find = fdata.filter((item)=> item.Pushkey===key)
                    // setsdata(find[0].product)
                    if(find.length>0){
                      console.log(find[0])
                      setdata(find[0])
                      setselldata(find[0].product[0])
                      setid(find[0].Url)
                    console.log(fdata,find,key);
                    }else{
                      console.log("Not Found")
                    }
                   
                } else {
                  console.log("No data available");
                }
              }).catch((error) => {
                console.error(error);
              });

          }else{
    
          }
        });
  
      }, [])
      useEffect(() => {
       console.log(selldata)
      }, [selldata])
      



    const getdata=()=>{
        get(child(dbRef, `product/`)).then((snapshot) => {
            if (snapshot.exists()) {
              console.log(Object.values(snapshot.val()));
            setpdata(Object.values(snapshot.val()))
            } else {
              console.log("No data available");
            }
          }).catch((error) => {
            console.error(error);
          });


          get(child(dbRef, `Vendor/`)).then((snapshot) => {
            if (snapshot.exists()) {
              const num = Object.values(snapshot.val());
              setVendorData(num)
              console.log(num);

            }
          }).catch((error) => {
            console.error(error);
          });

    }


    useEffect(() => {
      if (parseFloat(selldata.Mobile)) {
        const ku = VendorData.filter((item)=>(item.Other===selldata.Mobile))[0]
        if (ku) {
          setselldata({...selldata, name: ku.Fname,gst: ku.GST,PAN: ku.PAN,Address:ku.SPermanentAdd})
        }
      }
    }, [selldata.Mobile])

    useEffect(() => {

      onAuthStateChanged(auth, user =>{
        if(user){
          setAUser(user.email);
        }else{
  
        }
      });

      getdata()
    }, [])

    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "#D5FFFC"
        } else {
          return "white"
        }
      }

      useEffect(() => {

        if (selldata.totalweight&&selldata.rate&&selldata.bags) {
          if (selldata.weighttype==="kg") {
            const avrg = parseFloat(selldata.totalweight)/parseFloat(selldata.bags)
            const am = (parseFloat(selldata.totalweight)/100)*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
              
            }else if (selldata.weighttype==="qt") {
            const avrg = (parseFloat(selldata.totalweight)*100)/parseFloat(selldata.bags)
            const am = parseFloat(selldata.totalweight)*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
              
            }else if (selldata.weighttype==="mt") {
            const avrg = (parseFloat(selldata.totalweight)*1000)/parseFloat(selldata.bags)
            const am = parseFloat(selldata.totalweight)*10*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
            }
        }
        
      }, [selldata.totalweight,selldata.bags,selldata.rate,selldata.weighttype])
      

      const Add=()=>{
        setsdata(prevItems => [...prevItems,selldata]);
        console.log(sdata);
        // addProduct()
    }
    const removeItem = (index) => {
      if (sdata.length > 0) {
        const indexToRemove = index;
        const filteredData = sdata.filter((item, index) => index !== indexToRemove);
        setsdata(filteredData);
      }
    };
    const [Totalamount, setTotalamount] = useState("");
              let total;
              total = 0;
              useEffect(() => {
                for (let i = 0; i < sdata.length; i++){
                    const bal = sdata[i].Totalamount
                    total = total + parseFloat(bal)
                }
                setTotalamount(total);
              }, [sdata])


    const Gbill=async()=>{
      await CreateVendor()
      updateData()
      // get(child(dbRef, `buy/`)).then((snapshot) => {
      //   if (snapshot.exists()) {
      //     const num = Object.values(snapshot.val());
      //     console.log(num.length);
      //     const c = num.length+1;
      //     upload(c)
   
      //   } else {
      //     const c = 1
      //     upload(c)

      //   }
      // }).catch((error) => {
      //   console.error(error);
      // });


      
        
      }


      const CreateVendor=()=>{
        get(child(dbRef, `Vendor/`)).then((snapshot) => {
          if (snapshot.exists()) {
            const num = Object.values(snapshot.val());
            console.log(num.length);
            const c = (num.length+1).toString();

            const code = selldata.name
            
            let admin_code;
            if (c.length === 1) {
                admin_code = "00" + c;
            } else if (c.length === 2) {
                admin_code = "0" + c;
            } else {
                admin_code = c;
            }

            const mycode = code[0]+admin_code

            if (num.filter((item)=>(item.Other===selldata.Mobile)).length<1) {
              set(ref(db, 'Vendor/'+ mycode+'/'), {
                BranchName:'001',
                Receiptdate:moment(new Date()).format('yyyy-MM-DD'),
                VendorType:'*',
                Title:'*',
                Fname:selldata.name,
                Lname:'*',
                Designation:'*',
                FirmName:'*',
                FirmType:"*",
                FirmReg:'*',
        
                SPermanentAdd:selldata.Address,
                SCity:'*',
                Istate:'*',
                District:'*',
                SPinCode:'*',
                SameAdd:'yes',
        
                SSResidanceAdd:selldata.Address,
                SSCity:'*',
                SSIstate:'*',
                SSDistrict:'*',
        
                PAN:selldata.PAN,
                GST:selldata.gst,
                Other:selldata.Mobile,
                AcNumber:'*',
                Ifsc:'*',
                Bank:'*',
                Branch:'001',EmpID:'*',
                Code:mycode
              }).then(()=>console.log('Vendor Created'));
            }

           


          } else {
            const code = selldata.name
            const mycode = code[0]+'001'


              set(ref(db, 'Vendor/'+ mycode+'/'), {
                BranchName:'001',
                Receiptdate:moment(new Date()).format('yyyy-MM-DD'),
                VendorType:'*',
                Title:'*',
                Fname:selldata.name,
                Lname:'*',
                Designation:'*',
                FirmName:'*',
                FirmType:"*",
                FirmReg:'*',
        
                SPermanentAdd:selldata.Address,
                SCity:'*',
                Istate:'*',
                District:'*',
                SPinCode:'*',
                SameAdd:'yes',
        
                SSResidanceAdd:selldata.Address,
                SSCity:'*',
                SSIstate:'*',
                SSDistrict:'*',
        
                PAN:selldata.PAN,
                GST:selldata.gst,
                Other:selldata.Mobile,
                AcNumber:'*',
                Ifsc:'*',
                Bank:'*',
                Branch:'001',EmpID:'*',
                Code:mycode
              }).then(()=>console.log('Vendor Created'));
         

            

   
          }
        }).catch((error) => {
          console.error(error);
        });
      }

      // const upload=(c)=>{

      // const newPostKey = push(child(ref(db), 'buy/')).key;
      //   const data = {
      //     Name: sdata[0].name,
      //     GST: sdata[0].gst,
      //     Agent: sdata[0].agent,
      //     Pushkey: newPostKey,
      //     Total:Totalamount,
      //     Date:new Date(),
      //     Email: AUser,
      //     Url : id,
      //     Status: 'pending',
      //     BillNumber: c,
      //     product: 
      //       sdata
          
      //   };
      //   update(ref(db, 'buy/'+ newPostKey+'/'), data).then(()=>navi('/'));
      //   console.log("Done")
      // }

      const updateData = () => { 
        const data2 = {
          Name: sdata[0].name,
          GST: sdata[0].gst,
          Agent: sdata[0].agent,
          Total: Totalamount,
          Date: new Date(),
          Pushkey:data.Pushkey,
          Email: AUser,
          Url: id,
          Status: 'pending',
          BillNumber: data.BillNumber,
          product: sdata
        };
      
        // Use the existing key to update data in the database
        update(ref(db, 'buy/' + key + '/'), data2)
          .then(() => navi('/preq'))
          .catch(error => console.error("Error updating data:", error));
      
        console.log("Update successful");
      }

      const [showme, setshowme] = useState(false)


      useEffect(() => {

        if (selldata.weighttype==="kg") {
          const avrg = (parseFloat(selldata.weight)*parseFloat(selldata.deduction))/100
          const am = parseFloat(selldata.weight)-avrg
          setselldata({...selldata, deductionweight: avrg.toFixed(2),totalweight: am.toFixed(2)})
  
            
          }else if (selldata.weighttype==="qt") {
          const avrg = parseFloat(selldata.weight)*parseFloat(selldata.deduction)
          const am = parseFloat(selldata.weight)-(avrg/100)
          setselldata({...selldata, deductionweight: avrg.toFixed(2),totalweight: am.toFixed(2)})
  
            
          }else if (selldata.weighttype==="mt") {
          const avrg = (parseFloat(selldata.weight)*parseFloat(selldata.deduction))*10
          const am = parseFloat(selldata.weight)-(avrg/1000)
          setselldata({...selldata, deductionweight: avrg.toFixed(2),totalweight: am.toFixed(2)})
  
          }
      }, [selldata.deduction,selldata.weighttype,selldata.weight])
      

      useEffect(() => {
        if (parseFloat(selldata.qualityclaim)>0) {
          setselldata({...selldata, Totalamount: selldata.amount-selldata.qualityclaim})
        } else {
          setselldata({...selldata, Totalamount:selldata.amount})
        }
      }, [selldata.qualityclaim,selldata.Totalamount])
      



      if (showme) {
        return(
          <>

        <div style={{
              display:'flex',
              backgroundColor:'white',
              boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          }}>
              <div style={{
                  textAlign:'start',
                  width:'60%'
              }}>
                  <h1 style={{
                      color:'#0BC89F',
                      margin:'13px 0 15px 15px',
                      fontSize:22,
                      fontWeight:700
                  }}>MOUNTREACH</h1>
        
              </div>
              <div style={{
                  textAlign:'end',
                  width:'40%'
              }}>
              {/* <i style={
                  {
                      padding:10,
                      backgroundColor:'#EAEAEA',
                      borderRadius:20,
                      margin:'10px 20px 10px 0'
                  }
              }  className="fa-solid fa-right-from-bracket"></i> */}
              </div>
          </div>
            
            <div style={{
                textAlign:'center'
            }}>
        
        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, name: e.target.value})}
                value={selldata.name}
        label='Name of Seller'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}
        
        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, gst: e.target.value})}
                value={selldata.gst}
        label='GST No.'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, PAN: e.target.value})}
                value={selldata.PAN}

        label='PAN No.'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, Mobile: e.target.value})}
                value={selldata.Mobile}
        label='Mobile No.'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, Address: e.target.value})}
                value={selldata.Address}
        label='Address'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}
        
        <div style={{margin:'20px 20% 0 20%'}}>
                <select style={{width:'100%',padding:'5px'}} value={selldata.product} onChange={(e)=>setselldata({...selldata, product: e.target.value})}>
                    <option>-Select Product-</option>
                {pdata.map((item, index) => (
          <option value={item.Name} key={index}>{item.Name}</option>
        ))}
                </select>
        </div>
        
        <div style={{margin:'20px 20% 0 20%'}}>
                <select style={{width:'100%',padding:'5px'}} value={selldata.weighttype} onChange={(e)=>setselldata({...selldata, weighttype: e.target.value})}>
                    <option>--select--</option>
                    <option value='kg'>Kilogram</option>
                    <option value='qt'>Quintal</option>
                    <option value='mt'>Metric Ton</option>
                
                </select>
        </div>
        
        
                <div style={{margin:'20px 20% 0 20%'}}>
                <MDBInput
                onChange={(e)=>setselldata({...selldata, bags: e.target.value})}
                value={selldata.bags}
        label='Number of Bags'
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>
        
        <div style={{margin:'20px 20% 0 20%'}} >
                <MDBInput
                onChange={(e)=>setselldata({...selldata, weight: e.target.value})}
                value={selldata.weight}
        label='Weight'
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>

        <div style={{margin:'20px 20% 0 20%'}} >
                <MDBInput
                onChange={(e)=>setselldata({...selldata, deduction: e.target.value})}
                value={selldata.deduction}
        label={'Bags Wt Detection Per QT ( KG- '+ selldata.deductionweight +', QTL- '+selldata.deductionweight/100 +', MT- '+selldata.deductionweight/1000+' )'}
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>

        <div style={{margin:'20px 20% 0 20%'}} >
                <MDBInput
                // onChange={(e)=>setselldata({...selldata, weight: e.target.value})}
                value={selldata.totalweight}
        label='Total Net Weight'
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>
        
        <div style={{margin:'20px 20% 20px 20%'}}>
        <MDBInput
        onChange={(e)=>setselldata({...selldata, rate: e.target.value})}
        value={selldata.rate}
        label='Rate'
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>
        
        {sdata.length<1&&<div style={{margin:'20px 20% 20px 20%'}}>
        <MDBInput
        onChange={(e)=>setselldata({...selldata, agent: e.target.value})}
        value={selldata.agent}
        label='Agent'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        <div style={{margin:'20px 20% 20px 20%'}}>
<MDBInput
value={selldata.amount}
label='Total Amount'
type='number'
width={80}
style={{width:'80%'}}
/>
</div>

<div style={{margin:'20px 20% 0 20%'}} >
                <MDBInput
                onChange={(e)=>setselldata({...selldata, qualityclaim: e.target.value})}
                value={selldata.qualityclaim}
        label='Quality Claim in rupee'
        type='number'
        width={80}
        style={{width:'80%'}}
        />
        </div>


        <div style={{margin:'20px 20% 20px 20%'}}>
<MDBInput
value={selldata.Totalamount}
label='Total Net Amount'
type='number'
width={80}
style={{width:'80%'}}
/>
</div>

        {sdata.length<1&&<div style={{margin:'20px 20% 20px 20%'}}>
        <MDBInput
        onChange={(e)=>setselldata({...selldata, paid: e.target.value})}
        value={selldata.paid}
        label='Advance'
        type='text'
        width={80}
        style={{width:'80%'}}
        />
        </div>}

        <div style={{margin:'20px 20% 20px 20%'}}>
<MDBInput
value={selldata.Totalamount-selldata.paid}
label='Balance'
type='number'
width={80}
style={{width:'80%'}}
/>
</div>
        

     <FileUp x = {id} setX={setid} title='Receipt'/>
      <MDBBtn onClick={Add} className='me-1' color='success' style={{width:'60%'}}>
        Add
        </MDBBtn>
        {sdata.length>0&&<>
          <div style={{display:"flex",justifyContent:"center"}}>
        <table style={{width:"95%",marginTop:20}}>
                       <thead> <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
                            <th style={{padding:5}}>S.no.</th>
                            <th>Product Name</th>
                            <th>Number of Bags</th>
                            <th>Average</th>
                            <th>Weight</th>
                            <th>deduction</th>
                            <th>Net Weight</th>
                            <th>Rate</th>
                            <th>Quality Claim</th>
                            <th>Amount</th>
                            <th>Edit</th>
                            <th></th>
                        </tr></thead> 
                        <tbody>
                        {sdata.map((item, index) => (
                        <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
                              <td style={{padding:5}}>{index + 1}</td>
                              <td>{item.product}</td>
                              <td>{item.bags}</td>
                              <td>{item.average}</td>
                              <td>{item.weight}</td>
                              <td>{item.deduction}</td>
                              <td>{item.totalweight}</td>
                              <td>{item.rate}</td>
                              <td>{item.qualityclaim}</td>
                              <td>{item.Totalamount}</td>
                              {/* <td><i onClick={()=>setselldata(item)} className="fa-solid fa-pen"></i></td> */}
                              <td><i onClick={()=>removeItem(index)} className="fa-solid fa-xmark"></i></td>
                              
                        </tr>))}
                        {
                    sdata.length!==0&&  <tr style={{fontSize:12,backgroundColor:'#D9E1FF',fontWeight:'bold',marginBottom:"50px"}}>
                    <td style={{padding:5}}>{'>'}</td>
                    <td style={{padding:5}}>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{Totalamount}</td>
                    <td></td>
                    
                    <td></td>
                  </tr>
                  }
                        </tbody>
                        </table>
        
        </div>
        <MDBBtn onClick={Gbill} className='me-1' color='success' style={{width:'60%'}}>
        UPDATE
        </MDBBtn>
        
        </>
        }
        
        
        <br></br>
<br></br>
<button style={{width:200}} type="button" class="btn btn-warning" onClick={()=>{navi('/')}} data-mdb-ripple-init>Back to Home</button>

<br></br>
<br></br>
<br></br>

        
            </div>
            </>
        )
      } else {
        return(
          <div>
            Loading...
          </div>
        )
      }


}

export default BuyEdit
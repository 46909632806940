import React,{useState,useRef,useEffect} from 'react';
import axios from 'axios';
import moment  from 'moment';
// import Financialyear from '../Financialyear';
// import { useTranslation } from 'react-i18next';

function JournalVoucher(props) {
  // const { t } = useTranslation();
    const date = new Date();
    const formattedDate = moment(date).format('yyyy-MM-DD');
    const [Ddate, setDdate] = useState(formattedDate);

    const [type, settype] = useState("");
    const [branch, setbranch] = useState("");
    const [Ledgername, setLedgername] = useState("");
    const [Balance, setBalance] = useState("");
    const [loading, setloading] = useState(false);
    const [year, setyear] = useState("");

    const [items, setItems] = useState([]);

    const [alertcolor, setalertcolor] = useState('green');
    const [alert, setalert] = useState(false);
    const [alertmsg, setalertmsg] = useState(null);
    
    const myalart =(color,msg,btn)=>{
        setalertcolor(color);
        setalertmsg(msg);
        setalert(btn);
      }

      useEffect(() => {
        const timer = setTimeout(() => {
          alert && setalert(false);
        }, 3000);
        return () => clearTimeout(timer);
      }, [alert]);
      // const dateInputRef = useRef(null);
      // useEffect(() => {
      //   if (dateInputRef.current) {
      //     dateInputRef.current.blur();
      // }
      // }, [Ddate])
      //get branch name

      const [Dbranch, setDbranch] = useState([]);

      const getBData = async()=>{
        const api = 'https://api.mountreachpos.duniyape.in'+'/fatch-branch/'+process.env.REACT_APP_API_KEY
        try{
          const res = await axios.get(api);
          if(res){
            setDbranch(Object.values(res.data));
            console.log(Object.values(res.data));
          }
          
        }catch(error){
          console.log(error);
        }
      }
    
    useEffect(() => {
    getBData();
    }, [])



    const [ldata, setlData] = useState([]);

    const getData = async()=>{

      const api = 'https://api.mountreachpos.duniyape.in'+'/get-ledger/'+process.env.REACT_APP_API_KEY
      try{
        const res = await axios.get(api);
        if(res){
          setlData(Object.values(res.data));
          console.log(Object.values(res.data));
        }
        
      }catch(error){
        console.log(error);
      }
    }
  
    useEffect(() => {
        getData();
      }, [])

      const color =(index)=>{
        if ((index+1)% 2===0) {
          return "#D5FFFC"
        } else {
          return "white"
        }
      }

      const Add = () => {
        const gname=ldata.filter(item=>item.Pushkey===Ledgername)[0].GroupType
        const newlname=ldata.filter(item=>item.Pushkey===Ledgername)[0].LedgerName
        let arr=[...items]
        arr= [...arr, {
               date:Ddate,
               lname:Ledgername,
               BranchCode:branch,
               Type:type,
               Balance:Balance,
               Gname:gname,
              ledgerName:newlname
        }]
        console.log(arr)
        setItems(arr)
        // setbranch("")
        setLedgername("")
        setBalance("")
        settype("")
      };

      const removeItem = (index) => {
        if (items.length > 0) {
          const indexToRemove = index;
          const filteredData = items.filter((item, index) => index !== indexToRemove);
          setItems(filteredData);
        }
      };

      const create= async()=>{
        setloading(true);
        // Voucher()
        const api='https://api.mountreachpos.duniyape.in'+'/ganeral-account-debit/'+process.env.REACT_APP_API_KEY
        try {
          await axios.post(api,items)
          .then((res)=>{
            console.log(res.data)
            setloading(false);
            myalart('green','Successful',true);
            setItems([])
          })
        } catch (error) {
          console.log(error)
          setloading(false);
        }

      }

      // const Voucher=async()=>{
      //   let data={
      //     date:Ddate,
      //     lname:Ledgername,
      //     BranchCode:branch,
      //     Balance:TotalCr,
      //   }
      //   setloading(true)
      //   try {
      //     const res= await axios.post("https://api.mountreachpos.duniyape.in/ganeral-account-debit-voucher/"+process.env.REACT_APP_API_KEY,data)
      //     console.log(res.data)
      //     setloading(false)
      //     myalart('green','Submitted succesfully',true);
          
      //   } catch (error) {
      //     console.log(error)
      //     setloading(false)
      //   }
      // }

      
      const [TotalDr, setTotalDr] = useState("");
      const [TotalCr, setTotalCr] = useState("");
      let totalDr = 0;
      let totalCr = 0;
      useEffect(() => {
        for (let i = 0; i < items.length; i++){
            const bal = items[i].Balance
            if(items[i].Type==="Dr"){
              totalDr = totalDr + parseFloat(bal)
            }else{
              totalCr = totalCr + parseFloat(bal)
            }
        }
        setTotalDr(totalDr);
        setTotalCr(totalCr);
      }, [items])



  return (
    <div style={{
        width:"100%"
    }}>

      
{alert?<><div style={{
  width:"100%",
  backgroundColor:alertcolor,
  color:"white",
  textAlign:'center',
  padding:8,
}}>
  <h6 style={{fontSize:12}}>{alertmsg}</h6>
</div></>:<></>}
        
        <div style={{
        margin:"20px 2% 0px 2%",  
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
        // justifyContent:"space-around"
    }}>

<div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white"}}>Journal Voucher</div>
        
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
<input value={Ddate} onChange={(e)=>setDdate(e.target.value)} placeholder='Select DOB' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} disabled type='date'/>
</div>

{/* <div style={{padding:5, textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Branch Name</label>
            <select value={branch} onChange={(e)=>setbranch(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
            <option value="">--Select--</option>
                {props.EmpBranch==='Admin'?
                  Dbranch.map((item , index)=>(
                    <option key={index} value={item.BranchCode}>{item.BranchName}</option>
                  )):<option value={props.EmpBranch}>{props.EmpBr}</option>
                }
        </select>
        </div> */}


<div style={{padding:5, textAlign:"end",width:300}}>
      <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Select Ledger</label>
      <select value={Ledgername} onChange={(e)=>setLedgername(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
          <option value="">-Select-</option>
          {
            ldata.map((item , index)=>(
              <option key={index} value={item.Pushkey}>{item.LedgerName}</option>
            ))
          }      
  </select>
  </div>
<div style={{padding:5, textAlign:"end",width:300}}>
      <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Type</label>
      <select value={type} onChange={(e)=>settype(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
          <option value="">-Select-</option>
          <option value="Dr">Dr</option>  
          <option value="Cr">Cr</option>  
  </select>
  </div>



  <div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Amount</label>
<input value={Balance} onChange={(e)=>setBalance(e.target.value)} placeholder={'Enter Amount'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

<div style={{width:"100%", textAlign:"end"}}>
<button onClick={Add} style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12,backgroundColor:"#322869"}} type="button" className="btn btn-success">ADD</button>
</div>

      <table style={{width:"100%",marginTop:20}}>
      <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
          <th style={{padding:5}}>S.no.</th>
          <th>Ledger Name</th>
          <th>DR</th>
          <th>CR</th>
          <th></th>
      </tr>

      {items.filter(item=>item.BranchCode===branch).map((item , index)=>(
          <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
            <td style={{padding:5}}>{index + 1}</td>
            <td>{item.ledgerName}</td>
            {
              item.Type==="Dr"?<> 
              <td>{item.Balance}</td>
              <td></td>
              </>:<>
              <td></td>
              <td>{item.Balance}</td>
              </>
            }
            
           
            <td><i onClick={()=>removeItem(index)} className="fa-solid fa-xmark"></i></td>
          </tr>
        ))
        
      }
  
  {
    items.length!==0&&  <tr style={{fontSize:12,backgroundColor:'#D9E1FF',fontWeight:'bold',marginBottom:"50px"}}>
    <td style={{padding:5}}>{'>'}</td>
    <td style={{padding:5}}>Total</td>
    <td>{TotalDr}</td>
    <td>{TotalCr}</td>
    <td></td>
  </tr>
  }
     

  </table>

  
  {
loading? <div style={{width:"100%", height:100, textAlign:"end"}}>
<span class="loader"></span>
</div>
:
<div style={{width:"100%", textAlign:"end"}}>
<button onClick={create} style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12,backgroundColor:"#322869"}} type="button" className="btn btn-success">SUBMIT</button>
</div>

}

 

</div>
</div>
  )
}

export default JournalVoucher
import React, { useEffect, useState } from 'react'
import { getDatabase, ref, update,remove,child,get} from "firebase/database";
import {app} from "./api.js";
import html2pdf from 'html2pdf.js';
import { MDBBtn } from 'mdb-react-ui-kit';
import {useNavigate} from 'react-router-dom'
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { MyNav } from './MyNav.js';
import logo from './mylogo.png'
import Sign from './Sign.png'
import CashNumbering from './CashNumbering.js';
import axios from 'axios';
import moment from 'moment';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const auth = getAuth(app);
const db = getDatabase(app);
const dbRef = ref(getDatabase(app));




const GenBill = () => {

  const [payment, setpayment] = useState(false)
  const [Mode, setMode] = useState("Cash");
  const [ChequeNo, setChequeNo] = useState("");
  const [debitto, setdebitto] = useState(""); 
  const [Bankname, setBankname] = useState("");
  const [BankRefNo, setBankRefNo] = useState("");
  const [DateOfPayment, setDateOfPayment] = useState("");
  const [branch, setbranch] = useState('001');
  const [loading, setloading] = useState(false);
  const [Amount, setAmount] = useState('')
  const [Remark, setRemark] = useState('')
  const [alertcolor, setalertcolor] = useState('green');
  const [alert, setalert] = useState(false);
  const [alertmsg, setalertmsg] = useState(null);
  
  const [DebitAssetstype, setDebitAssetstype] = useState("Assets-Cash");
  const myalart =(color,msg,btn)=>{
    setalertcolor(color);
    setalertmsg(msg);
    setalert(btn);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      alert && setalert(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [alert]);

  // const {id} = useParams();
  const [ldata, setldata] = useState([]);

  const getData = async()=>{
    const api = 'https://api.mountreachpos.duniyape.in'+'/get-ledger/I89nD59Ra69j0ee512TsW143eEt03u'
    try{
      const res = await axios.get(api);
      if(res){
        setldata(Object.values(res.data));
        console.log(Object.values(res.data));
      }
      
    }catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
      getData();
    }, [])




    const navi = useNavigate();
    useEffect(() => {
      onAuthStateChanged(auth, user =>{
        if(user){


          // get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
          //   if (snapshot.exists()) {
          //     const check = snapshot.val()
          //     if (check.status==="false") {
          //       navi('/wait')
          //     } else {
                
          //     }
          //   } else {
          //     console.log("No data available");
          //     navi('/')
          //   }
          // }).catch((error) => {
          //   console.error(error);
          //   navi('/')
          // });

                const em =  user.email
          
                get(child(dbRef, `adminemail/`)).then((snapshot) => {
                  if (snapshot.exists()) {
                    const radharani = snapshot.val()
                    if (radharani.email===em) {
                     
                    }else{
                      // navi('/')
        
                      }
                  } else {
                    // navi('/')
                  }
                }).catch((error) => {
                  navi('/')
                });

        }else{
          navi('/')
        }
      });
    }, [])

    const [viewbill, setviewbill] = useState(false);
    const [data, setdata] = useState([])

    const [bill, setbill] = useState("");

    const getdata=()=>{
        get(child(dbRef, `bills/`)).then((snapshot) => {
            if (snapshot.exists()) {

                const fdata = Object.values(snapshot.val())
                const pending = fdata.filter((item)=> item.Status==='pending')
              console.log(Object.values(snapshot.val()));
              setdata(pending)
            } else {
              console.log("No data available");
            }
          }).catch((error) => {
            console.error(error);
          });
    }

    useEffect(() => {
      getdata()
    }, [])


    const convertToPdf = () => {
        const element = document.getElementById('element-to-convert'); // Replace 'element-to-convert' with the ID of the HTML element you want to convert
        if (!element) {
          console.error('Element not found');
          return;
        }
        html2pdf()
          .from(element)
          .save();
      };




      const convertToWords = (num) => {
        const units = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
        const teens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
        const tens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    
        if (num === 0) return 'Zero';
    
        let words = '';
    
        if (num >= 10000000 && num <= 999999999) {
          words += convertToWords(Math.floor(num / 10000000)) + ' Crore ';
          num %= 10000000;
        }
    
        if (num >= 100000 && num <= 9999999) {
          words += convertToWords(Math.floor(num / 100000)) + ' Lakh ';
          num %= 100000;
        }
    
        if (num >= 1000 && num <= 99999) {
          words += convertToWords(Math.floor(num / 1000)) + ' Thousand ';
          num %= 1000;
        }
    
        if (num >= 100 && num <= 999) {
          words += units[Math.floor(num / 100)] + ' Hundred ';
          num %= 100;
        }
    
        if (num >= 20 && num <= 99) {
          words += tens[Math.floor(num / 10)] + ' ';
          num %= 10;
        }
    
        if (num >= 10 && num <= 19) {
          words += teens[num - 10];
          num = 0;
        }
    
        if (num >= 1 && num <= 9) {
          words += units[num];
        }
    
        return words.trim();
      };


const Accept=()=>{
      setpayment(true) 
      bill.product[0].tmobile&&create3(vid)
  }
  const create3=(code)=>{
    console.log(code)
    setloading(true);
    axios.post('https://api.mountreachpos.duniyape.in/'+'accept-purchase-voucher'+'/'+process.env.REACT_APP_API_KEY,{
        Balance : bill.product[0].freight,
        AccountNumber:tid,
        BranchCode:"001",
        date: moment().format("yyyy-MM-DD"),
        AccountType: '',
        ledgerName: 'A64',
        PaymentMode: Mode,
        DebitTo : debitto,
        ChequeNo : ChequeNo,
        BankName : Bankname,
        DateOfPayment : DateOfPayment,
        BankRef: BankRefNo,
        Remarks: Remark,EmpID:'',
        Advance:bill.product[0].advance,
        PurchaseLedger:"A87",
        Nareshan:'Transport'
    })
    .then(function (response) {
      console.log(response.data)
      if(response.data.error){
        // myalart('#DD4444','Deposit Failed',true);
        setloading(false);
      }else{
        // myalart('green',`Deposit Successfully ${response.data.VsKey?'Txn -'+response.data.VsKey:" "}`,true);
        // setAmount("");
        setloading(false);

        // get(child(dbRef, `buy/`)).then((snapshot) => {
        //   if (snapshot.exists()) {
        //     const num = Object.values(snapshot.val());
        //     const pending = num.filter((item)=> item.Status==='Accepted')
    
        //     const c = pending.length+201;
        //     update(ref(db, 'buy/'+ bill.Pushkey+'/'), {Status:'Accepted',BillNumber: c}).then(()=>navi('/'));
      
        //   } else {
        //     const c = 201
        //     update(ref(db, 'buy/'+ bill.Pushkey+'/'), {Status:'Accepted',BillNumber: c}).then(()=>navi('/'));
          
        //   }
        // }).catch((error) => {
        //   console.error(error);
        // });
      }
      
    })
    .catch(function (error) {
        console.log(error);
        // myalart('#DD4444','Failed',true);
        setloading(false);
        

    }); 
 
  }
  useEffect(() => {
    if (Mode==="Cheque") {
      setDebitAssetstype("Assets-Bank");
      setdebitto("");

    } else if(Mode==="Bank Transfer"){
      setDebitAssetstype("Assets-Bank");
      setdebitto("");
    } else if(Mode==="Cash"){
      setDebitAssetstype("Assets-Cash");
      setdebitto("A11");
    } else{
      setdebitto("");
    }
  }, [Mode])


  

  const checkMOP=()=>{
    let Tatalamount= bill.product[0].advance?bill.product[0].advance*1:0

    if (parseFloat(Tatalamount)===0) {
      setAmount(0)
    }

    
    
    const weighttype = bill.product[0].weighttype
      let weight = bill.product[0].weight

     
      if (weighttype==='qt') {
        weight = (bill.product[0].weight*100).toFixed(0)
      }
      if (weighttype==='mt'){
        weight = (bill.product[0].weight*1000).toFixed(0)
      }
      if (weighttype==='kg'){
        weight = parseFloat(bill.product[0].weight).toFixed(0)
      }

    if (InventoryLength>=parseFloat(weight)) {
      if (Mode==="Cheque") {
        if(debitto===""||ChequeNo===""||Bankname===""||DateOfPayment===""||Remark===''){
        myalart('#DD4444','Please fill Cheque Details',true);
        toast.error('Please fill Cheque Details!', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
          });
        }else{ 
             if (parseFloat(Tatalamount)!==parseFloat(Amount)) {
              myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
              toast.error('Please fill Details!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
            }else{
              create()
            }
        }
      } else if(Mode==="Bank Transfer") {
        if(debitto===""||BankRefNo===""||DateOfPayment===""||Remark===''){
          myalart('#DD4444','Please fill Bank Transfer Details',true);
          toast.error('Please fill Details!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
            });
        }else{ 
             if (parseFloat(Tatalamount)!==parseFloat(Amount)) {
              myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
              toast.error('Please fill Details!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
                });
            }else{
              create()
            }
        }
      }else if(Mode==="Cash"){
           if (parseFloat(Tatalamount)!==parseFloat(Amount)||Remark==='') {
            myalart('#DD4444','Deposit Amount is not Equal to Voucher Amount ',true);
            toast.error('Please fill Details!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light"
              });
          }else{
            create()
          }
  
      }
    }else{
      toast.error('Inventory Sortage!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
        });
    }

    
  }


  const [id2, setid2] = useState(null);
  useEffect(() => {
    if (id2===null) {
      
    } else {
      setAmount(id2.amount);
    }
  }, [id2])
  const [vid, setvid] = useState('')
  const [tid, settid] = useState('')
  const [vdata, setvdata] = useState([])
  const getvdata=async()=>{
    try {
        await axios.get('https://api.mountreachpos.duniyape.in/create-vendor/I89nD59Ra69j0ee512TsW143eEt03u')
        .then((res)=>{
            let data=Object.values(res.data)
            console.log(data)
            let f=data.filter(item=>item.GST===bill.GST)
            setvdata(data)
           if (f.length>0)
           {
            console.log(f[0].Code)
            setvid(f[0].Code)
           }
           else{
            myalart('#DD4444','Vendor Not Found',true)
          }
        })

    } catch (error) {
        console.log(error)
    }
}
useEffect(() => {
if(vdata.length>0&&bill!==''){
let find=vdata.filter(item=>item.Other===bill.product[0].tmobile)
if(find.length>0){
  settid(find[0].Code)
  console.log(find[0].Code)
}
}
}, [vdata])

useEffect(() => {
  console.log(bill)
  getvdata()
}, [bill])
  const create=()=>{
    setloading(true);

    console.log(vid)

    axios.post('https://api.mountreachpos.duniyape.in/'+'accept-sell-voucher'+'/'+process.env.REACT_APP_API_KEY,{
        Balance : bill.Total,
        AccountNumber:vid,
        BranchCode:"001",
        date: moment(bill.Date).format('yyyy-MM-DD'),
        AccountType: '',
        ledgerName: 'A64',
        PaymentMode: Mode,
        DebitTo : debitto,
        ChequeNo : ChequeNo,
        BankName : Bankname,
        DateOfPayment : DateOfPayment,
        BankRef: BankRefNo,
        Remarks: Remark,EmpID:'',
        // Advance:bill.product[0].paid,
        PurchaseLedger:"A69"
    })
    .then(function (response) {
      console.log(response.data)
      if(response.data.error){
        myalart('#DD4444','Deposit Failed',true);
        setloading(false);
      }else{
        
    Inventory(response.data.VsKey)


        // setAmount("");
        setloading(false);
        get(child(dbRef, `bills/`)).then((snapshot) => {
          if (snapshot.exists()) {
            const num = Object.values(snapshot.val());
            const pending = num.filter((item)=> item.Status==='Accepted')
            const c = pending.length+201;
            update(ref(db, 'bills/'+ bill.Pushkey+'/'), {Status:'Accepted',BillNumber: c}).then(()=>navi('/'));
      console.log("created")
          } else {
            const c = 201
            update(ref(db, 'bills/'+ bill.Pushkey+'/'), {Status:'Accepted',BillNumber: c}).then(()=>navi('/'));
      console.log("created2") 
          }
        }).catch((error) => {
          console.error(error);
        });
      }
    })
    .catch(function (error) {
        console.log(error);
        myalart('#DD4444','Failed',true);
        setloading(false);
    }); 
 
  }

  const [InventoryLength, setInventoryLength] = useState(10000000)
  const [list, setlist] = useState([])
  const GetInventory = async()=>{
    const api = 'https://api.mountreachpos.duniyape.in/product-inventory/add/'+process.env.REACT_APP_API_KEY
    try{
      const res = await axios.get(api);
      let data=Object.values(res.data);
      console.log(data)
      setlist(data)
      setInventoryLength(data.length)
    }catch(error){
      console.log(error);
    }
  }
  useEffect(() => {
   GetInventory()
  }, [])



const Inventory = async(k)=>{
   
    let AcNumber = vid

    // let data3=[{
    //   date:moment(bill.Date).format('yyyy-MM-DD'),
    //  BranchCode:branch,
    //  ledgerName:'A72',
    //  lname:"Sales Account",
    //  Balance:parseFloat(bill.Total).toFixed(2),
    //  Gname:'Income'
    //  },{
    //   date:moment(bill.Date).format('yyyy-MM-DD'),
    //   BranchCode:branch,
    //   ledgerName:'A79',
    //   lname:"Cess",
    //   Balance:0,
    //   Gname:'Asset-Current Asset',
    //   Product:''
    //   },{
    //     date:moment(bill.Date).format('yyyy-MM-DD'), 
    //   BranchCode:branch,
    //   ledgerName:'A15',
    //   lname:'GST',
    //   Balance:0,
    //   Gname:'',
    //   Product:''
    //   },{
    //     date:moment(bill.Date).format('yyyy-MM-DD'),
    //   BranchCode:branch,
    //   AcNumber,
    //   DebitTo:debitto,
    //   PaymentMode:Mode,
    //   ChequeNo,
    //   BankRef:BankRefNo,
    //   BankName:Bankname,
    //   Remarks:Remark,
    //   Balance:parseFloat(bill.Total).toFixed(2),EmpID:'x'
    //   }]
    //   console.log(data3)
    try{
      // const res = await axios.post('https://api.mountreachpos.duniyape.in/make-receipt-voucher/'+process.env.REACT_APP_API_KEY,data3);
      // console.log(res.data)

      const VsKey=k

      

      let data2={}
    console.log(bill.product,'sell api run')
    for (let i = 0; i < bill.product.length; i++) {

      const weighttype = bill.product[i].weighttype
      let weight = bill.product[i].weight

     
      if (weighttype==='qt') {
        weight = (bill.product[i].weight*100).toFixed(0)
      }
      if (weighttype==='mt'){
        weight = (bill.product[i].weight*1000).toFixed(0)
      }
      if (weighttype==='kg'){
        weight = parseFloat(bill.product[i].weight).toFixed(0)
      }


     data2={
      BranchCode:branch,
      Date:moment(bill.Date).format('yyyy-MM-DD'),
      Amount:bill.product[i].amount/weight,
      Taxblevalue:bill.product[i].amount/weight,
      BarCode:'A1',
      ItemNumber:'01',
      Qty:weight,
      ProductName:bill.product[i].product,
      VsKey:VsKey
      // VsKey:res.data.VsKey
     }

     let f=list.filter(item=>item.ItemNumber===data2.ItemNumber&&item.BranchCode===data2.BranchCode)

     let withkeyarry = []

     if(f.length>=data2.Qty){
       let arr=f.sort((a,b)=>new Date(a.Date)-new Date(b.Date)).slice(0,data2.Qty)
       console.log(arr)
       for (let j = 0; j < arr.length; j++) {
       
        withkeyarry = [...withkeyarry,{...data2,"key":arr[j].Key}]
      
    }
  }


     try{
      const res = await axios.post(`https://api.mountreachpos.duniyape.in/product-stock-ledger/xxxxxx/`+process.env.REACT_APP_API_KEY,withkeyarry);
      console.log(res.data)
      try{
        const res = await axios.post(`https://api.mountreachpos.duniyape.in/product-inventory/xxxxxx/`+process.env.REACT_APP_API_KEY,withkeyarry);
        console.log(res.data)

        myalart('green',`Deposit Successfully ${k?'Txn -'+k:" "}`,true);

        setloading(false);
      }catch(error){
        console.log(error);
      }

    }catch(error){
      console.log(error);
    }


    }

    }catch(error){
      console.log(error);
    }
  
  }

  const [Dbranch, setDbranch] = useState([]);

  const getBData = async()=>{
   
       const api = 'https://api.mountreachpos.duniyape.in/fatch-branch/'+process.env.REACT_APP_API_KEY
       try{
         const res = await axios.get(api);
         if(res){
           setDbranch(Object.values(res.data));
           console.log(Object.values(res.data));
         }
         
       }catch(error){
         console.log(error);
       }
     }
   
   useEffect(() => {
   getBData();
   }, [])





if(viewbill){
    return(
      <>

<ToastContainer />
      {bill&&<div style={{fontWeight:600}}>
      <div style={{backgroundColor:"black",display:"flex",flexDirection:"column",alignItems:"center",fontSize:"12px"}}>
          <div id="element-to-convert" style={{width:800,backgroundColor:"white",padding:"20px"}}>
    <div style={{position:"relative"}}>
     <div><img src={logo} alt='WaterMark' style={{width:"300px",position:'absolute',top: "30%", left: "30%",opacity:0.15}}></img></div>
          <div style={{border:"2px solid black",padding:"10px"}}>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div>सिवनी न्यायालय के अंतर्गत</div>
                  <div style={{marginLeft:"75px"}}>|| श्री गणेशाय नमः ||</div>
                  <div>Email: mountreach.orgnaic@gmail.com</div>
              </div>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div>GSTIN NO. -  23AALCM7165N1ZW</div>
                  <div style={{fontWeight:"bold"}}>Mob. 8319633066</div>
              </div>
              <div>
                  <div>मंडी लाइसेंस - 4562211-01012-00724</div>
              </div>
              <div style={{textAlign:"center",marginTop:"-30px"}}>
                  <div><img src={logo} alt='Logo' style={{width:"200px"}}></img></div>
                  <div>Reg. Off: Kendnlya Vdhyalya Road Kabir Ward Seoni Dist Seoni (M.P.) 480661</div>
                  <div style={{fontWeight:"bold"}}>All Types of Organic Grains, Maize, Wheat, Rice, Pulses Oil, Cattee feed All Agree Commodities</div>
              </div>
              <div style={{display:"flex",justifyContent:"space-between",marginTop:"20px"}}>
                  <div>क्र.{bill.BillNumber}</div>
                  <div>दिनांक - {moment(bill.Date).format('yyyy-MM-DD')}</div>
              </div>
              <div>क्रेता का नाम श्री {bill.Name}</div>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div> हस्ते.....................................................................................................................</div>
                  <div>ब्रोकर - {bill.Agent}</div> 
              </div>
              <div>क्रेता का GSTIN नं. - {bill.GST}</div>
              <div>
                  <table style={{width:"100%",border:"2px solid black",borderCollapse: "collapse",margin:"10px 0",textAlign:"center"}}>
                      <tr>
                          <th style={{border:"1px solid black",padding:"8px"}}>क्र.</th>
                          <th style={{border:"1px solid black"}}>माल का नाम</th>
                          <th style={{border:"1px solid black"}}>बोरा/बोरी</th>
                          <th style={{border:"1px solid black"}}>भरती</th>
                          <th style={{border:"1px solid black"}}>वजन</th>
                          <th style={{border:"1px solid black"}}>भाव</th>
                          <th style={{border:"1px solid black"}}>माल का कीमत</th>
                      </tr>
                     
                      {bill.product.map((item, index) => (
                          <tr>
                            <td style={{border:"1px solid black",padding:"8px"}}>{index+1}</td>
                          <td style={{border:"1px solid black"}}>{item.product}</td>
                          <td style={{border:"1px solid black"}}>{item.bags}</td>
                          <td style={{border:"1px solid black"}}>{item.average}</td>
                          <td style={{border:"1px solid black"}}>{item.weight}</td>
                          <td style={{border:"1px solid black"}}>{item.rate}</td>
                          <td style={{border:"1px solid black"}}>{(item.amount).toFixed(2)}/-</td>
                          </tr>
                      ))} 
          
                          <tr>
                            <td style={{border:"1px solid black",padding:"8px"}}></td>
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{border:"1px solid black"}}></td>
                          <td style={{border:"1px solid black",fontWeight:"bold"}}>कुल राशि -</td>
                          <td style={{border:"1px solid black",fontWeight:"bold"}}>{(bill.Total).toFixed(2)}/-</td>
                          
                          </tr>
                          
                         
                      
                  </table>
              </div>
              <div>कुल राशि शब्दों में - {convertToWords(bill.Total)} Only </div>
              <hr style={{border:" 1px solid black"}}/>

              <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div> कुल भाडा-{bill.product[0].freight}</div>
                  <div>एडवास.- {bill.product[0].advance}</div>
                  <div>  शेष भाडा..{bill.product[0].freight-bill.product[0].advance}....</div>
              </div>
              <hr style={{border:" 1px solid black"}}/>
              <div>नोट - माल तौलने के बाद जोखिम लेने वाले पर रहेगी । रकम एक सप्ताह में न आने से शुरू तिधि से ब्याज प्रति माह 2 रूपये सैकड़ा लगेगा। उपरोक्त बिल में जो माल निर्देशित है, उस माल के गुण की हम खात्री देते है (मूल चूक लेना देना) |</div>
              <hr style={{border:" 1px solid black"}}/>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                  <div> ट्रांसपोर्ट का नाम- {bill.product[0].transport}</div>
                  <div> मो. नं - {bill.product[0].tmobile}</div>
                  <div>वाहन क्रमांक - {bill.product[0].VehicleNo}</div>  
              </div> 
              <hr style={{border:" 1px solid black"}}/>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                 <div>ड्राइवर का नाम -  {bill.product[0].Dname}</div> 
                 <div>मो. नं० - {bill.product[0].Dnumber}</div>
                 <div>लाइसेंस नं - {bill.product[0].Lnumber}</div> 
              </div> 
          
              <hr style={{border:" 1px solid black"}}/>
              
              <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>उपरोक्त बिल का पेमेन्ट हमारे खाते में ही डालें।</div> 
                    <div><img src={Sign} style={{width:"100px"}}></img></div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>Central Bank Of India, Branch - Kanjai</div>
                    <div style={{fontWeight:600,color:'blue'}}>Auth. Signatory</div>
                </div>
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>A/c-5469996873</div>
                    <div style={{fontWeight:900,color:'blue'}}>MOUNTREACH ORAGANIC</div>
                </div>
              
                <div style={{display:"flex",justifyContent:"space-between"}}>
                    <div>IFSC Code -CBIN0282672</div>
                    <div style={{fontWeight:900,color:'blue'}}>ARTIFACT PVT. LTD.</div>
                </div>
              </div>
          </div>
  </div>
  <div style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px"}}>
    <MDBBtn rounded className='mx-2' color='primary' onClick={convertToPdf}>Convert to PDF</MDBBtn>
    <MDBBtn rounded className='mx-2' color='primary' onClick={Accept}>Accept</MDBBtn>
    <MDBBtn rounded className='mx-2' color='primary' onClick={()=>remove(ref(db, 'bills/'+ bill.Pushkey+'/')).then(()=>navi('/'))}>Delete</MDBBtn>
    <MDBBtn rounded className='mx-2' color='primary' onClick={()=>setviewbill(false)}>Back</MDBBtn>
  
            
  </div>
      </div>

      {payment&&<div style={{
  margin:"20px 2% 0px 2%",
  display:'flex',
  flexDirection:'row',
  flexFlow:'wrap',
  // justifyContent:"space-around"
}}>
<div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"30px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Payment</div>

<div style={{padding:5, textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Branch Name</label>
            <select value={branch} onChange={(e)=>setbranch(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
            <option value="">--Select--</option>
                {Dbranch.map((item , index)=>(
                    <option key={index} value={item.BranchCode}>{item.BranchName}</option>
                  ))}
        </select>
        </div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Payment mode</label>
<select value={Mode} onChange={(e)=>setMode(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
    <option value="">-Select-</option>     
    <option value="Cash">Cash</option>     
    <option value="Cheque">Cheque</option>     
    <option value="Bank Transfer">Bank Transfer</option>        
</select>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Credit To</label>
<select value={debitto} onChange={(e)=>setdebitto(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
  <option value="">-Select-</option>
    {
ldata.filter((item)=> item.GroupName===DebitAssetstype).map((item , index)=>(
  <option key={index} value={item.Pushkey}>{item.LedgerName}</option>
))
}
</select>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Amount</label>
<input value={bill.product[0].advance?bill.product[0].advance*1:0}  placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>


{
Mode==="Cash"?<>
<CashNumbering x = {id2} setX={setid2} text={"Deposit"}/>
</>:<></>
}

{
Mode==="Cheque"?<>
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>cheque No.</label>
<input value={ChequeNo} onChange={(e)=>setChequeNo(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Bank Name</label>
<input value={Bankname} onChange={(e)=>setBankname(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
<input value={DateOfPayment} onChange={(e)=>setDateOfPayment(e.target.value)} placeholder='Enter Plan Name' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
</div>

<div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Update Plan</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Deposit Amount</label>
<input value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='number'/>
</div>
</>:<></>
}


{
Mode==="Bank Transfer"?<>
<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Bank Ref. No.</label>
<input value={BankRefNo} onChange={(e)=>setBankRefNo(e.target.value)} placeholder='Enter Plan Name'style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Date</label>
<input  value={DateOfPayment} onChange={(e)=>setDateOfPayment(e.target.value)} placeholder='Enter Plan Name' style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='date'/>
</div>


<div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Update Plan</div>

<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Deposit Amount</label>
<input value={Amount} onChange={(e)=>setAmount(e.target.value)} placeholder={'Enter Amount'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='number'/>
</div>
</>:<></>
}


<div style={{padding:5, textAlign:"end",width:300}}>
<label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Remarks</label>
<input value={Remark} onChange={(e)=>setRemark(e.target.value)} placeholder={'Enter Remarks'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='text'/>
</div>

{
loading? <div style={{width:"100%", height:100, textAlign:"end"}}>
<span class="loader"></span>
</div>
:
<div style={{width:"100%", height:100, textAlign:"end"}}>
<button onClick={checkMOP}  style={{borderRadius:2,padding:"5px 30px 5px 30px",margin:"20px 10px 0px 0px", fontSize:12,backgroundColor:"#322869"}} type="button" className="btn btn-success">Create</button>
</div>
}

    
    </div>}
       
  </div>
}
<p style={{margin:20}}>Dharam Kanta Receipt</p>
{
  bill.Url&&<div style={{width:'100%',textAlign:'center',backgroundColor:'black'}}><img src={bill.Url} alt='img'/></div>
}

<p style={{margin:20}}>Mandi Anugya Patra</p>
{
  bill.pUrl&&<div style={{width:'100%',textAlign:'center',backgroundColor:'black'}}><img src={bill.pUrl} alt='img'/></div>
}
</>
        
    )
}else{
    return (
        <div>
          <MyNav/>
            {data.map((item, index) => (
  <div style={{display:"flex",justifyContent:"space-evenly",alignItems:"center", boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',margin:"3%",padding:"5px"}}>
     <div style={{padding:"5px"}}>
          <div>{item.Name}</div>  
          <div>{item.Email}</div>  
          <div>{item.Date}</div>  
     </div>
     <div>
     <MDBBtn rounded color='info gradient' style={{marginRight:10}} onClick={()=>{setviewbill(true);setbill(item)}}>View</MDBBtn>
     <MDBBtn rounded color='info gradient' onClick={()=>navi(`/sell_edit/${item.Pushkey}`)}>Edit</MDBBtn>
    </div>
  </div>
))}
        </div>
        )
}

  
}

export default GenBill


import React, { useEffect, useState } from 'react'
import { getDatabase, ref,child,get} from "firebase/database";
import {app} from "./api.js";
import { MDBBtn } from 'mdb-react-ui-kit';
import {useNavigate} from 'react-router-dom'
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import moment from 'moment';
import { MyNav } from './MyNav.js';
import * as XLSX from 'xlsx';
import {MDBIcon } from 'mdb-react-ui-kit';


const auth = getAuth(app);
const db = getDatabase(app);
const dbRef = ref(getDatabase(app));

export const PurcheseAdmin = () => {

  const [viewbill, setviewbill] = useState(false);
    const [data, setdata] = useState([])

    const [bill, setbill] = useState([]);
    const [fromdate, setfromdate] = useState(moment(new Date()).format('yyyy-MM-DD'));
    const [Todate, setTodate] = useState(moment(new Date()).format('yyyy-MM-DD'));

    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "#D5FFFC"
        } else {
          return "white"
        }
      }

    // const startDate = moment(fromdate).format('yyyy-MM-DD');
    // const endDate = moment(Todate).format('yyyy-MM-DD');
  
    // const filteredData = data.filter(item => {
    //   const formattedDate = moment(item.date).format('yyyy-MM-DD');
    //   const itemDate = moment(formattedDate).format('yyyyMMDD')
    //   const start = moment(startDate).format('yyyyMMDD')
    //   const end = moment(endDate).format('yyyyMMDD')
  
    //   return itemDate >= start && itemDate <= end;
    // });


    const navi = useNavigate();
    useEffect(() => {
      onAuthStateChanged(auth, user =>{
        if(user){

          const em =  user.email

          // get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
          //   if (snapshot.exists()) {
          //     const check = snapshot.val()
          //     if (check.status==="false") {
          //       navi('/wait')
          //     } else {
                
          //     }
          //   } else {
          //     console.log("No data available");
          //     navi('/')
          //   }
          // }).catch((error) => {
          //   console.error(error);
          //   navi('/')
          // });
          
          get(child(dbRef, `adminemail/`)).then((snapshot) => {
            if (snapshot.exists()) {
              const radharani = snapshot.val()
              if (radharani.email===em) {
               
              }else{
                navi('/')
  
                }
            } else {
              navi('/')
            }
          }).catch((error) => {
            navi('/')
          });
        }else{
          navi('/')
        }
      });
    }, [])

    const newdata=data.map(i=>({
      BillNumber:i.BillNumber,
      Date:moment(i.Date).format('yyyy-MM-DD'),
      Name:i.Name,
      PAN:i.product[0].PAN,
      GST:i.GST,
      Address:i.product[0].Address,
      product:i.product[0].product,
      Mobile:i.product[0].Mobile,
      Agent:i.Agent,
      bags:i.product[0].bags,
      rate:i.product[0].rate,
      weight:i.product[0].weight,
      weighttype:i.product[0].weighttype,
      average:i.product[0].average,
      amount:i.product[0].amount,
      paid:i.product[0].paid,
    //   MandiTax:i.MandiTax,
    //   Commison:i.Commison,
    //   OtherTax:i.OtherTax,
      Total:i.Total,

    }))

    const getdata=()=>{
        get(child(dbRef, `buy/`)).then((snapshot) => {
            if (snapshot.exists()) {

                const fdata = Object.values(snapshot.val())
                const pending = fdata.filter((item)=> item.Status==='Accepted')
              console.log(Object.values(snapshot.val()));
              setdata(pending)
              console.log(newdata)

            } else {
              console.log("No data available");
            }
          }).catch((error) => {
            console.error(error);
          });
    }

    useEffect(() => {
      getdata()


    }, [])

    
      const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(newdata);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Employees');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const filename = 'EmployeesData.xlsx';
        saveAsExcelFile(excelBuffer, filename);
      };
    
      const saveAsExcelFile = (buffer, filename) => {
        const data = new Blob([buffer], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(data);
        link.download = filename;
        link.click();
      };

  return (
    <div>
    <MyNav/>
    <div style={{
margin:"20px 10% 0px 10%",
display:'flex',
flexDirection:'row',
flexFlow:'wrap',
justifyContent:"space-around"
    }}>
      <div>
          <label>From Date</label>
      <input type='date' value={fromdate} onChange={(e)=>setfromdate(e.target.value)} style={{width:200,padding:5,marginLeft:5}}/>
      </div>
      
      
      <div>
          <label>To Date</label>
      <input type='date' value={Todate} onChange={(e)=>setTodate(e.target.value)} style={{width:200,padding:5,marginLeft:5}}/>
      </div>

      <MDBBtn className='me-1' color='success' onClick={exportToExcel}>
      Download Excel File
    </MDBBtn>
    </div>
<div style={{display:"flex",justifyContent:"center"}}>
<table style={{width:"95%",marginTop:20,textAlign:"center"}}>
             <thead> <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
                  <th style={{padding:5}}>S.no.</th>
                  <th>Bill Number</th>
                  <th>Agent</th>
                  <th>Date</th>
                  <th>Name</th>
                  <th>PAN</th>
                  <th>GST</th>
                  <th>Address</th>
                  <th>Mobile</th>
                  <th>Product</th>
                  <th>Bags</th>
                  <th>rate</th>
                  <th>weight</th>
                  <th>Weight Type</th>
                  <th>Average</th>
                  <th>Amount</th>
                  {/* <th>Mandi Tax</th> */}
                  {/* <th>Commission</th> */}
                  {/* <th>Other Tax</th> */}
                  <th>Total</th>
                  <th>Paid</th>
                  <th>Balance</th>
                  <th>Acknowledgement</th>
              </tr></thead> 
              <tbody>
              {newdata.filter((item)=>(moment(item.Date).format('yyyyMMDD')>=moment(fromdate).format('yyyyMMDD')&&moment(item.Date).format('yyyyMMDD')<=moment(Todate).format('yyyyMMDD'))).map((item, index) => (
              <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
                    <td style={{padding:5}}>{index + 1}</td>
                    <td>{item.BillNumber}</td>
                    <td>{item.Agent}</td>
                    <td>{item.Date}</td>
                    <td>{item.Name}</td>
                    <td>{item.PAN}</td>
                    <td>{item.GST}</td>
                    <td>{item.Address}</td>
                    <td>{item.Mobile}</td>
                    <td>{item.product}</td>
                    <td>{item.bags}</td>
                    <td>{item.rate}</td>
                    <td>{item.weight}</td>
                    <td>{item.weighttype}</td>
                    <td>{item.average}</td>
                    <td>{item.amount}</td>
                    {/* <td>{item.MandiTax}</td> */}
                    {/* <td>{item.Commison}</td> */}
                    {/* <td>{item.OtherTax}</td> */}
                    <td>{item.Total}</td>
                    <td>{item.paid}</td>
                    <td>{item.Total-item.paid}</td>
                    <td><MDBIcon fas icon='download' onClick={()=>{navi('/ekc/'+item.BillNumber)}} /></td>

              </tr>))}
              </tbody>
              </table>

</div>
  </div>
  )
}

import React, { useEffect, useState } from 'react'
import imageCompression from 'browser-image-compression';
import { getStorage,ref, uploadBytes,getDownloadURL } from "firebase/storage";
import {app} from "./api.js";

const storage = getStorage(app);

const FileUp = (props) => {


    const [upphoto, setupphoto] = useState(null);
useEffect(() => {
 setupphoto(props.x)
}, [props.x])


const handlephoto = async(e) => {
  const file = e.target.files[0];
  const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {

    const random = Math.floor(Math.random() * (1000000 - 1 + 1)) + 1;
        const ul = 'images/'+new Date()+random

      const compressedFile = await imageCompression(file, options);
    //   setmupphoto(compressedFile);
      const reader = new FileReader();
      reader.onload = () => {
          setupphoto(reader.result);
          uploadBytes(ref(storage, ul), compressedFile).then((snapshot) => {
            
            getDownloadURL(ref(storage, ul))
                .then((url) => {
                    props.setX(url);
                })
          });
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.log(error);
    }
};




  return (
    
    <div style={{padding:5, textAlign:"start",margin:'20px 20% 20px 20%',backgroundColor:'#EDEDED'}}>
      <label>{props.title}</label>
    <input accept="image/*" onChange={handlephoto} className="filestyle" type='file'/>
    {upphoto && <img src={upphoto} style={{width:200,height:110,margin:"10px 5px 10px 5px",borderRadius:10}} alt='img'/>}
    </div>
    
  )
}

export default FileUp


import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update,push,child,get, set} from "firebase/database";
import {app} from "./api.js";
import {useNavigate} from 'react-router-dom'
import FileUp from './FileUp.js';
import Wait from './Wait.js';
import moment from 'moment';
import axios from 'axios';


const db = getDatabase(app);
const dbRef = ref(getDatabase(app));
const auth = getAuth(app);




export const Sell = () => {

  const [id, setid] = useState(null);
  const [pid, setpid] = useState(null);

    const [pdata, setpdata] = useState([]);
    const [selldata, setselldata] = useState([]);
    const [sdata, setsdata] = useState([]);

  const [AUser, setAUser] = useState("");
  const [tview, settview] = useState(false);

  const [showme, setshowme] = useState(false);
  const [loading, setloading] = useState(false);

  const navi = useNavigate();
  useEffect(() => {
    onAuthStateChanged(auth, user =>{
      if(user){


        get(child(dbRef, `auth/`+user.uid)).then((snapshot) => {
          if (snapshot.exists()) {
            const check = snapshot.val()
            if (check.status==="false") {
              navi('/wait')
            } else {
              setshowme(true);
            }
          } else {
            console.log("No data available");
            navi('/')
          }
        }).catch((error) => {
          console.error(error);
          navi('/')
        });


      }else{
        navi('/')
      }
    });
  }, [])

  const [VendorData, setVendorData] = useState([])


    const getdata=async()=>{
      const api = 'https://api.mountreachpos.duniyape.in/trading-add-product/'+process.env.REACT_APP_API_KEY
      try{
        const res = await axios.get(api);
        console.log(res.data)
        setpdata(Object.values(res.data))
      }catch(error){
        console.log(error);

      }




          get(child(dbRef, `Vendor/`)).then((snapshot) => {
            if (snapshot.exists()) {
              const num = Object.values(snapshot.val());
              setVendorData(num)
              console.log(num);

            }
          }).catch((error) => {
            console.error(error);
          });


    }

    useEffect(() => {
      if (selldata.gst) {
        const ku = VendorData.filter((item)=>(item.GST===selldata.gst))[0]
        if (ku) {
          setselldata({...selldata, name: ku.Fname,gst: ku.GST})
        }
        console.log(ku,VendorData)
        

      }
    }, [selldata.gst])
    useEffect(() => {
      if (selldata.tmobile) {

        const ku = VendorData.filter((item)=>(item.Other===selldata.tmobile))[0]
        if (ku) {
          setselldata({...selldata, transport:ku.Fname })
        }else{
          setselldata({...selldata,transport:''})
        }
        console.log(ku)
      }
    }, [selldata.tmobile])

    useEffect(() => {

      onAuthStateChanged(auth, user =>{
        if(user){
          setAUser(user.email);
        }else{
  
        }
      });

      getdata()
    }, [])

    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "#D5FFFC"
        } else {
          return "white"
        }
      }

      useEffect(() => {

        if (selldata.weight&&selldata.rate&&selldata.bags) {
          if (selldata.weighttype==="kg") {
            const avrg = parseFloat(selldata.weight)/parseFloat(selldata.bags)
            const am = (parseFloat(selldata.weight)/100)*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
              
            }else if (selldata.weighttype==="qt") {
            const avrg = (parseFloat(selldata.weight)*100)/parseFloat(selldata.bags)
            const am = parseFloat(selldata.weight)*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
              
            }else if (selldata.weighttype==="mt") {
            const avrg = (parseFloat(selldata.weight)*1000)/parseFloat(selldata.bags)
            const am = parseFloat(selldata.weight)*10*parseFloat(selldata.rate)
            setselldata({...selldata, average: avrg.toFixed(2),amount: am.toFixed(2)})
    
            }
        }
        
      }, [selldata.weight,selldata.bags,selldata.rate,selldata.weighttype])
      

      const Add=()=>{
        setsdata(prevItems => [...prevItems,selldata]);
        console.log(sdata);
        // addProduct()
    }
    const removeItem = (index) => {
      if (sdata.length > 0) {
        const indexToRemove = index;
        const filteredData = sdata.filter((item, index) => index !== indexToRemove);
        setsdata(filteredData);
      }
    };
    const [Totalamount, setTotalamount] = useState("");
              let total;
              total = 0;
              useEffect(() => {
                for (let i = 0; i < sdata.length; i++){
                    const bal = sdata[i].amount
                    total = total + parseFloat(bal)
                }
                setTotalamount(total);
              }, [sdata])


               const CreateVendor=()=>{
        get(child(dbRef, `Vendor/`)).then((snapshot) => {
          if (snapshot.exists()) {
            const num = Object.values(snapshot.val());
            console.log(num.length);
            const c = (num.length+1).toString();

            const code = selldata.name
            
            let admin_code;
            if (c.length === 1) {
                admin_code = "00" + c;
            } else if (c.length === 2) {
                admin_code = "0" + c;
            } else {
                admin_code = c;
            }

            const mycode = code[0]+admin_code

            if (num.filter((item)=>(item.GST===selldata.gst)).length<1) {
              set(ref(db, 'Vendor/'+ mycode+'/'), {
                BranchName:'001',
                Receiptdate:moment(new Date()).format('yyyy-MM-DD'),
                VendorType:'*',
                Title:'*',
                Fname:selldata.name,
                Lname:'*',
                Designation:'*',
                FirmName:'*',
                FirmType:"*",
                FirmReg:'*',
        
                SPermanentAdd:'*',
                SCity:'*',
                Istate:'*',
                District:'*',
                SPinCode:'*',
                SameAdd:'yes',
        
                SSResidanceAdd:"*",
                SSCity:'*',
                SSIstate:'*',
                SSDistrict:'*',
        
                PAN:'*',
                GST:selldata.gst,
                Other:'*',
                AcNumber:'*',
                Ifsc:'*',
                Bank:'*',
                Branch:'001',EmpID:'*',
                Code:mycode
              }).then(()=>{if(selldata.freight>0)
                {CreateVendor2()}}
              );
            }

           


          } else {
            const code = selldata.name
            const mycode = code[0]+'001'


              set(ref(db, 'Vendor/'+ mycode+'/'), {
                BranchName:'001',
                Receiptdate:moment(new Date()).format('yyyy-MM-DD'),
                VendorType:'*',
                Title:'*',
                Fname:selldata.name,
                Lname:'*',
                Designation:'*',
                FirmName:'*',
                FirmType:"*",
                FirmReg:'*',
        
                SPermanentAdd:'*',
                SCity:'*',
                Istate:'*',
                District:'*',
                SPinCode:'*',
                SameAdd:'yes',
        
                SSResidanceAdd:'*',
                SSCity:'*',
                SSIstate:'*',
                SSDistrict:'*',
        
                PAN:"*",
                GST:selldata.gst,
                Other:"*",
                AcNumber:'*',
                Ifsc:'*',
                Bank:'*',
                Branch:'001',EmpID:'*',
                Code:mycode
              }).then(()=>{if(selldata.freight>0)
                {CreateVendor2()}}
              );
         

            

   
          }
        }).catch((error) => {
          console.error(error);
        });
      }
               const CreateVendor2=()=>{
        get(child(dbRef, `Vendor/`)).then((snapshot) => {
          if (snapshot.exists()) {
            const num = Object.values(snapshot.val());
            console.log(num.length);
            const c = (num.length+1).toString();

            const code = selldata.transport
            
            let admin_code;
            if (c.length === 1) {
                admin_code = "00" + c;
            } else if (c.length === 2) {
                admin_code = "0" + c;
            } else {
                admin_code = c;
            }

            const mycode = code[0]+admin_code
            let find=num.filter((item)=>(item.Other===selldata.tmobile))

            if (find.length<1) {
              set(ref(db, 'Vendor/'+ mycode+'/'), {
                BranchName:'001',
                Receiptdate:moment(new Date()).format('yyyy-MM-DD'),
                VendorType:'*',
                Title:'*',
                Fname:selldata.transport,
                Lname:'*',
                Designation:'*',
                FirmName:'*',
                FirmType:"*",
                FirmReg:'*',
        
                SPermanentAdd:'*',
                SCity:'*',
                Istate:'*',
                District:'*',
                SPinCode:'*',
                SameAdd:'yes',
        
                SSResidanceAdd:"*",
                SSCity:'*',
                SSIstate:'*',
                SSDistrict:'*',
        
                PAN:'*',
                GST:'*' ,
                Other:selldata.tmobile,
                AcNumber:'*',
                Ifsc:'*',
                Bank:'*',
                Branch:'001',EmpID:'*',
                Code:mycode
              }).then(()=>{
                console.log('transport Vendor Created')
            });
            }
          } else {
            const code = selldata.name
            const mycode = code[0]+'001'


              set(ref(db, 'Vendor/'+ mycode+'/'), {
                BranchName:'001',
                Receiptdate:moment(new Date()).format('yyyy-MM-DD'),
                VendorType:'*',
                Title:'*',
                Fname:selldata.transport,
                Lname:'*',
                Designation:'*',
                FirmName:'*',
                FirmType:"*",
                FirmReg:'*',
        
                SPermanentAdd:'*',
                SCity:'*',
                Istate:'*',
                District:'*',
                SPinCode:'*',
                SameAdd:'yes',
        
                SSResidanceAdd:'*',
                SSCity:'*',
                SSIstate:'*',
                SSDistrict:'*',
        
                PAN:"*",
                GST:"*",
                Other:selldata.tmobile,
                AcNumber:'*',
                Ifsc:'*',
                Bank:'*',
                Branch:'001',EmpID:'*',
                Code:mycode
              }).then(()=>{
                console.log('TRANSPORT Vendor Created')
            });
          }
        }).catch((error) => {
          console.error(error);
        });
      }
     



      const upload=(c)=>{

        CreateVendor()
        

      const newPostKey = push(child(ref(db), 'bills/')).key;

      const fpdata = pdata.filter((item)=>item.Name===sdata[0].product)
      
      console.log(fpdata)

        const data = {
          Name: sdata[0].name,
          GST: sdata[0].gst,
          Agent: sdata[0].agent,
          Commision:((fpdata[0].Commision/sdata[0].amount)*100).toFixed(2),
          MandiTax:((fpdata[0].MandiTax/sdata[0].amount)*100).toFixed(2),
          OtherTax:((fpdata[0].OtherTax/sdata[0].amount)*100).toFixed(2),
          Pushkey: newPostKey,
          Total:parseFloat(Totalamount).toFixed(0),
          Date:new Date(),
          Email: AUser,
          Status: 'pending',
          BillNumber: 'pending',
          Url : id,
          pUrl : pid?pid:'none',
          product: 
            sdata
          
        };

        update(ref(db, 'bills/'+ newPostKey+'/'), data).then(()=>navi('/'));
       
      }

      if (showme) {
        return (<>

          <div style={{
                display:'flex',
                backgroundColor:'white',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            }}>
                <div style={{
                    textAlign:'start',
                    width:'60%'
                }}>
                    <h1 style={{
                        color:'#0BC89F',
                        margin:'13px 0 15px 15px',
                        fontSize:22,
                        fontWeight:700
                    }}>MOUNTREACH</h1>
          
                </div>
                <div style={{
                    textAlign:'end',
                    width:'40%'
                }}>
                {/* <i style={
                    {
                        padding:10,
                        backgroundColor:'#EAEAEA',
                        borderRadius:20,
                        margin:'10px 20px 10px 0'
                    }
                }  className="fa-solid fa-right-from-bracket"></i> */}
                </div>
            </div>
              
              <div style={{
                  textAlign:'center'
              }}>
          
          {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                  <MDBInput
                  onChange={(e)=>setselldata({...selldata, name: e.target.value})}
                  value={selldata.name}
          label='Name of Purchaser'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>}
          
          {sdata.length<1&&<div style={{margin:'20px 20% 0 20%'}}>
                  <MDBInput
                  onChange={(e)=>setselldata({...selldata, gst: e.target.value})}
          label='GST No.'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>}
          
          <div style={{margin:'20px 20% 0 20%'}}>
                  <select style={{width:'100%',padding:'5px'}} onChange={(e)=>setselldata({...selldata, product: e.target.value})}>
                      <option>-Select Product-</option>
                  {pdata.map((item, index) => (
            <option value={item.Name} key={index}>{item.Name}</option>
          ))}
                  </select>
          </div>
          
          <div style={{margin:'20px 20% 0 20%'}}>
                  <select style={{width:'100%',padding:'5px'}} onChange={(e)=>setselldata({...selldata, weighttype: e.target.value})}>
                      <option>--select--</option>
                      <option value='kg'>Kilogram</option>
                      <option value='qt'>Quintal</option>
                      <option value='mt'>Metric Ton</option>
                  
                  </select>
          </div>
          
          
                  <div style={{margin:'20px 20% 0 20%'}}>
                  <MDBInput
                  onChange={(e)=>setselldata({...selldata, bags: e.target.value})}
          label='Number of Bags'
          type='number'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 0 20%'}} >
                  <MDBInput
                  onChange={(e)=>setselldata({...selldata, weight: e.target.value})}
          label='Weight'
          type='number'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, rate: e.target.value})}
          label='Rate'
          type='number'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          value={selldata.amount}
          label='Total Amount'
          type='number'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          {sdata.length<1&&<div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, agent: e.target.value})}
          label='Agent'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>}
          
          {tview===false&&<MDBBtn outline rounded color='warning' style={{width:'60%',marginBottom:20}} onClick={()=>settview(true)}>
                  Transport Details
                </MDBBtn>}
          
          {tview===true&&<>
            <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, tmobile: e.target.value})}
          label='Mobile Number'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>

          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, transport: e.target.value})}
          value={selldata.transport}
          label='Transporter Name'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
         
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, VehicleNo: e.target.value})}
          value={selldata.VehicleNo}
          label='Vehicle Number'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, Dname: e.target.value})}
          value={selldata.Dname}
          label='Driver Name'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, Dnumber: e.target.value})}
          label='Driver Mobile Number'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, Lnumber: e.target.value})}
          label='License Number'
          type='text'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, freight: e.target.value})}
          label='Transportation charges'
          type='number'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          onChange={(e)=>setselldata({...selldata, advance: e.target.value})}
          label='Advance'
          type='number'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          <div style={{margin:'20px 20% 20px 20%'}}>
          <MDBInput
          value={selldata.freight-selldata.advance}
          onChange={(e)=>setselldata({...selldata, balance: e.target.value})}
          label='Balance'
          type='number'
          width={80}
          style={{width:'80%'}}
          />
          </div>
          
          </>
          }
          
          <FileUp x = {id} setX={setid} title='Dharam Kanta Receipt'/>

          <FileUp x = {pid} setX={setpid} title='Mandi Anugya Patra'/>
          
          {id&&<MDBBtn onClick={Add} className='me-1' color='success' style={{width:'60%'}}>
          Add
          </MDBBtn>}
          
          
          {sdata.length>0&&<>
            <div style={{display:"flex",justifyContent:"center"}}>
          <table style={{width:"95%",marginTop:20}}>
                         <thead> <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
                              <th style={{padding:5}}>S.no.</th>
                              <th>Product Name</th>
                              <th>Number of Bags</th>
                              <th>Average</th>
                              <th>Weight</th>
                              <th>Rate</th>
                              <th>Amount</th>
                              <th>Edit</th>
                          </tr></thead> 
                          <tbody>
                          {sdata.map((item, index) => (
                          <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
                                <td style={{padding:5}}>{index + 1}</td>
                                <td>{item.product}</td>
                                <td>{item.bags}</td>
                                <td>{item.average}</td>
                                <td>{item.weight}</td>
                                <td>{item.rate}</td>
                                <td>{item.amount}</td>
                                <td><i onClick={()=>removeItem(index)} className="fa-solid fa-xmark"></i></td>
                                
                          </tr>))}
                          {
                      sdata.length!==0&&  <tr style={{fontSize:12,backgroundColor:'#D9E1FF',fontWeight:'bold',marginBottom:"50px"}}>
                      <td style={{padding:5}}>{'>'}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{padding:5}}>Total</td>
                      <td>{Totalamount}</td>
                      <td></td>
                    </tr>
                    }
                          </tbody>
                          </table>
          
          </div>
          <MDBBtn onClick={upload} className='me-1' color='success' style={{width:'60%'}}>
          Send Bill For Approval
          </MDBBtn>
          </>
          }
          
          
          
          <br></br>
          <br></br>
          <button style={{width:200}} type="button" class="btn btn-warning" onClick={()=>{navi('/')}} data-mdb-ripple-init>Back to Home</button>
          
          <br></br>
          <br></br>
          <br></br>
          
          
              </div>
              </>
            )
      } else {
        return(
          <div>
            loading...
          </div>
        )
      }

  
}

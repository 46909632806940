import React, { useEffect, useState,useRef } from 'react';

  const CashNumbering = (props) => {

  // //Notes count

  const [fivehundred, setfivehundred] = useState("");
  const [fivehundred0, setfivehundred0] = useState(0.00);
  const [twohundred, settwohundred] = useState("");
  const [twohundred0, settwohundred0] = useState(0);
  const [hundred, sethundred] = useState("");
  const [hundred0, sethundred0] = useState(0);
  const [pachash, setpachash] = useState("");
  const [pachash0, setpachash0] = useState(0);
  const [twenty, settwenty] = useState("");
  const [twenty0, settwenty0] = useState(0);
  const [ten, setten] = useState("");
  const [ten0, setten0] = useState(0);
  const [five, setfive] = useState("");
  const [five0, setfive0] = useState(0);
  const [two, settwo] = useState("");
  const [two0, settwo0] = useState(0);
  const [one, setone] = useState("");
  const [one0, setone0] = useState(0);

  const [Amount, setAmount] = useState("");

  const Count={
    x500:fivehundred,
    x200:twohundred,
    x100:hundred,
    x50:pachash,
    x20:twenty,
    x10:ten,
    x5:five,
    x2:two,
    x1:one,
    amount:Amount,
    Type: props.text
  }

  useEffect(() => {
  
    if (Amount==="") {
        
    }else if (Amount<=0) {
        setAmount("");
    }else{
      // console.log(Count)
        props.setX(Count);
    }
  }, [Amount])
  

  useEffect(() => {
     setAmount(parseFloat(fivehundred0)+parseFloat(twohundred0)+parseFloat(hundred0)+parseFloat(pachash0)+parseFloat(twenty0)+parseFloat(ten0)+parseFloat(five0)+parseFloat(two0)+parseFloat(one0));
    // if (fivehundred!==0||twohundred!==0||hundred!==0||pachash!==0||twenty!==0||ten!==0||five!==0||two!==0||one!==0) {
    //   setDepositedit(false);
    // }else{
    //   setDepositedit(true);
    // }
    if(!fivehundred){
      setfivehundred0(0);
    }else if(!twohundred){
      settwohundred0(0);
    }else if(!hundred){
      sethundred0(0);
    }else if(!pachash){
      setpachash0(0);
    }else if(!twenty){
      settwenty0(0);
    }else if(!ten){
      setten0(0);
    }else if(!five){
      setfive0(0);
    }else if(!two){
      settwo0(0);
    }else if(!one){
      setone0(0);
    }

  }, [fivehundred,twohundred,hundred,pachash,twenty,ten,five,two,one])


  return (
    <>
    <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>CASH ENTRY</div>
    
    <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>500 x</label>
          <input value={fivehundred} onChange={(e)=>{setfivehundred(e.target.value);setfivehundred0(parseInt((e.target.value))*500)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='number'>{fivehundred0}</label>
      </div>

      <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>200 x</label>
          <input value={twohundred} onChange={(e)=>{settwohundred(e.target.value);settwohundred0(parseInt((e.target.value))*200)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='text'>{twohundred0}</label>
      </div>

      <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>100 x</label>
          <input value={hundred} onChange={(e)=>{sethundred(e.target.value);sethundred0(parseInt((e.target.value))*100)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='text'>{hundred0}</label>
      </div>

      <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>50 x</label>
          <input value={pachash} onChange={(e)=>{setpachash(e.target.value);setpachash0(parseInt((e.target.value))*50)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='text'>{pachash0}</label>
      </div>

      <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>20 x</label>
          <input value={twenty} onChange={(e)=>{settwenty(e.target.value);settwenty0(parseInt((e.target.value))*20)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='text'>{twenty0}</label>
      </div>
      <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>10 x</label>
          <input value={ten} onChange={(e)=>{setten(e.target.value);setten0(parseInt((e.target.value))*10)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='text'>{ten0}</label>
      </div>

      <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>5 x</label>
          <input value={five} onChange={(e)=>{setfive(e.target.value);setfive0(parseInt((e.target.value))*5)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='text'>{five0}</label>
      </div>

      <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>2 x</label>
          <input value={two} onChange={(e)=>{settwo(e.target.value);settwo0(parseInt((e.target.value))*2)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='text'>{two0}</label>
      </div>

      <div style={{padding:5, textAlign:"end",width:300}}>
          <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>1 x</label>
          <input value={one} onChange={(e)=>{setone(e.target.value);setone0(parseInt((e.target.value))*1)}} placeholder={'Enter No.'} style={{fontSize:12,padding:"3px 10px 5px 10px",width:89}} type='number'/>
          <label style={{fontSize:12,padding:"3px 10px 5px 10px",width:80,border:"1px solid black"}} type='text'>{one0}</label>
      </div>

      <div style={{width:"100%",padding:"5px 5px 5px 20px",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,color:"white"}}>Pay Amount</div>

<div style={{padding:5, textAlign:"end",width:300}}>
    <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Amount</label>
    <input value={Amount} placeholder={'Amount'}style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}} type='number'/>
</div>

      </>
  )
}

export default CashNumbering
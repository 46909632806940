import React, { useEffect,useRef, useState } from 'react'
import axios from "axios";
// import statejosn from './statedistrict.json';
import moment from 'moment';
import { Page, Text, View, Document, StyleSheet,PDFViewer} from '@react-pdf/renderer';
import { MyNav } from './MyNav';
import VendorInfo from './VendorInfo'
import {useNavigate} from 'react-router-dom'


const VendorList = (props) => {
  const navigate = useNavigate();
    const [filter, setfilter] = useState("");
    // const [filterdata, setfilterdata] = useState(false);
    const [data, setdata] = useState([]);
    const [Vdata, setVdata] = useState([]);
    const [mdata, setmData] = useState([]);
    const [loading, setloading] = useState(false);

    const [Edit, setEdit] = useState(false);
    const date = new Date();
    const formattedDate = moment(date).format('YYYY-MM-DD');

    const [alertcolor, setalertcolor] = useState('green');
    const [alert, setalert] = useState(false);
    const [alertmsg, setalertmsg] = useState(null);
  const [printdata, setprintdata] = useState(false);
    
    const myalart =(color,msg,btn)=>{
        setalertcolor(color);
        setalertmsg(msg);
        setalert(btn);
      }
      useEffect(() => {
        const timer = setTimeout(() => {
          alert && setalert(false);
        }, 3000);
        return () => clearTimeout(timer);
      }, [alert]);
  
      const [aadhartext, setaadhartext] = useState("");
  const [aadharcolor, setaadharcolor] = useState("red");
  

    // const [filteredData, setFilteredData] = useState(statejosn);
    // const [sfilteredData, setsFilteredData] = useState(statejosn);


  const [branchnm, setbranchnm] = useState("");
  const [code, setcode] = useState("");
  const [receiptdate, setreceiptdate] = useState(formattedDate);
  const [vendortype, setvendortype] = useState("");
  
  const [title, settitle] = useState("Mr");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [designation, setdesignation] = useState("");
  
  const [firmname, setfirmname] = useState("");
  const [firmtype, setfirmtype] = useState("");
  const [firmReg, setfirmReg] = useState("");


  const [Spermanentadd, setSpermanentadd] = useState("");
  const [Scity, setScity] = useState("");
  const [istate, setistate] = useState("Andhra Pradesh");
  const [district, setdistrict] = useState("Anantapur");
  const [Spincode, setSpincode] = useState("");
  const [sameadd, setsameadd] = useState("Yes")

  const [SSresidanceadd, setSSresidanceadd] = useState("");
  const [SScity, setSScity] = useState("");
  const [SSdistrict, setSSdistrict] = useState("Anantapur");
  const [SSistate, setSSistate] = useState("Andhra Pradesh");
  const [SSpincode, setSSpincode] = useState("");
  const [pan, setpan] = useState("");
  const [gst, setgst] = useState("");
  const [other, setother] = useState("");
  const [acnumber, setacnumber] = useState("");
  const [ifsc, setifsc] = useState("");
  const [bank, setbank] = useState("");
  const [branch, setbranch] = useState("001");

 
     // loading screen show

   const [submilshow, setsubmilshow] = useState(false);

   const SameAddress =(e)=>{

    setsameadd(e.target.value);

    if(e.target.value==="No"){
        setSSresidanceadd(Spermanentadd);
        setSScity(Scity);
        setSSistate(istate);
        setSSdistrict(district);
        setSSpincode(Spincode);
    }else if(e.target.value==="Yes"){
        setSSresidanceadd("");
        setSScity("");
        setSSistate("Andhra Pradesh");
        setSSdistrict("");
        setSSpincode("");
    }
}

    const color =(index)=>{
        if ((index+1)% 2===0) {
          return "#D5FFFC"
        } else {
          return "white"
        }
      }
      const styles = StyleSheet.create({
        page: {
          padding:"30px 2px 30px 2px",
        },
        section: {
         
          padding: 10,
        
        }
      });
      const [year, setyear] = useState("");

      const [Dbranch, setDbranch] = useState([]);
                
      const getBData = async()=>{

        const api = 'https://api.mountreachpos.duniyape.in'+'/fatch-branch/'+"I89nD59Ra69j0ee512TsW143eEt03u"
        try{
          const res = await axios.get(api);
          if(res){
            setDbranch(Object.values(res.data));
            console.log(Object.values(res.data));
          }
          
        }catch(error){
          console.log(error);
        }
        }
    
    useEffect(() => {
    getBData();
    }, [])

const getdata=async()=>{
    try {
        await axios.get('https://api.mountreachpos.duniyape.in/create-vendor/'+"I89nD59Ra69j0ee512TsW143eEt03u")
        .then((res)=>{
            console.log(Object.values(res.data))
            setVdata(Object.values(res.data))
        })

    } catch (error) {
        console.log(error)
    }
}
useEffect(()=>{
getdata()
},[Edit])

const check=()=>{
  if(branch===""||vendortype===""){
    myalart('#DD4444','Please select Branch',true);
  }else if(fname===""||lname===""||designation===""){
    myalart('#DD4444','Please Enter Authorised Persson Details',true);
  }else if(firmname===""||firmtype===""||firmReg===""){
    myalart('#DD4444','Please Enter Firm Details',true);
  }else if(Spermanentadd===""||Scity===""||istate===""||district===""||Spincode===""){
    myalart('#DD4444','Please Enter Registerd Address Details',true);
  }else if(SSresidanceadd===""||SScity===""||SSistate===""||SSdistrict===""){
    myalart('#DD4444','Please Enter Billing Address Details',true);
  }else if(pan===""||gst===""){
    myalart('#DD4444','Please Enter TAX Details',true);
  }else if(acnumber===""||bank===""||ifsc===""||branchnm===""){
    myalart('#DD4444','Please Enter Bank Details',true);
  }else{
    Post()
  }
}


const create={
  BranchName:branch,
  Receiptdate:receiptdate,
  Code:code,
  VendorType:vendortype,
  Title:title,
  Fname:fname,
  Lname:lname,
  Designation:designation,
  FirmName:firmname,
  FirmType:firmtype,
  FirmReg:firmReg,

  SPermanentAdd:Spermanentadd,
  SCity:Scity,
  Istate:istate,
  District:district,
  SPinCode:Spincode,
  SameAdd:sameadd,

  SSResidanceAdd:SSresidanceadd,
  SSCity:SScity,
  SSIstate:SSistate,
  SSDistrict:SSdistrict,

  PAN:pan,
  GST:gst,
  Other:other,
  AcNumber:acnumber,
  Ifsc:ifsc,
  Bank:bank,
  Branch:branchnm,EmpID:props.username
}

const Post= async ()=>{
  setsubmilshow(true)
  const api = 'https://api.mountreachpos.duniyape.in/Update-vendor/'+"I89nD59Ra69j0ee512TsW143eEt03u"
  try{
    await axios.post(api,create)
    .then((res)=>{
      console.log(res.data);
      setsubmilshow(false);
      myalart('green','Successful',true);
      setEdit(false);
    })
    }catch(error){
      console.log(error);
      setsubmilshow(false);
    myalart('#DD4444','Internal Error',true);


    }

  
}


if (Edit) {
  return (
    <VendorInfo/>
  )
    }else{
  return (
<>

{printdata?<>
  <div style={{
    position:'fixed',
    top:"12%",
    right:'10%',
    left:'10%',
    bottom:'10%',
    backgroundColor:'white',
    boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
    borderRadius:8
  }}>

<div style={{width:'100%',textAlign:'right',padding:'0 8px 0 0',color:'black'}}>
<i onClick={()=>setprintdata(false)} class="fa-solid fa-xmark"></i></div>

<PDFViewer style={{width:"100%",height:"92%"}}>
          <Document style={{fontSize:8}} >
          <Page size="A4" style={styles.page}>
          <View>
                  <Text style={{textAlign:"center",fontSize:14,marginBottom:10}}>Vendor List</Text>
          </View>
          <View style={{flexDirection:"row",justifyContent:"space-evenly",margin:"0px 10px 10px 10px",padding:"5px 10px", backgroundColor:"#F0F0F0"}}>
          <Text style={{width:"3vw"}}>Sno.</Text>
          <Text style={{width:"15vw"}}>Enrollment Date</Text>
          <Text style={{width:"15vw"}}>Firm Name</Text>
          <Text style={{width:"15vw"}}>Type</Text>
          <Text style={{width:"15vw"}}>Authorised Person</Text>
          <Text style={{width:"15vw"}}>Mobile No</Text>
          </View>
          {Vdata.filter(item=>item.BranchName===branch).map((item,index)=>(
            <View style={{flexDirection:"row",justifyContent:"space-evenly" ,padding:"3px 0px"}}>
            <Text style={{width:"3vw",textAlign:"center"}}>{index+1}</Text>
            <Text style={{width:"15vw",textAlign:"center"}}>{item.Receiptdate}</Text>
            <Text style={{width:"15vw"}}>{item.FirmName}</Text>
            <Text style={{width:"15vw"}}>{item.FirmType}</Text>
            <Text style={{width:"15vw"}}>{item.Fname} {item.Lname}</Text>
            <Text style={{width:"15vw"}}>*****</Text>
           
            </View>
          ))}
        </Page>
          </Document>
  
        </PDFViewer>

  </div>
  </>:<></>
}

    <div style={{
        width:"100%"
    }}>

        <MyNav/>
        
        <div style={{
        margin:"20px 2% 0px 2%",
        display:'flex',
        flexDirection:'row',
        flexFlow:'wrap',
        // justifyContent:"space-around"
    }}>
<div style={{width:"100%",padding:"8px 5px 8px 20px",textTransform:"Uppercase",margin:"10px 0px 10px 0px",backgroundColor:"#636694",fontSize:10,fontWeight:"bold", color:"white",display:'flex',justifyContent: 'space-between'}}>Vendor List<div style={{display:'flex'}}><p style={{margin:0,paddingRight:20}} onClick={()=>setprintdata(true)}><i style={{marginRight:5}} className="fa-solid fa-download"></i>DOWNLOAD PDF</p></div></div>

{/* 
<div style={{padding:5, textAlign:"end",width:300}}>
            <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>Branch Name</label>
            <select value={branch} onChange={(e)=>setbranch(e.target.value)} style={{fontSize:12,padding:"3px 10px 5px 10px",width:169}}>
            <option value="">--Select--</option>
                {
                // props.EmpBranch==='Admin'?
                  Dbranch.map((item , index)=>(
                    <option key={index} value={item.BranchCode}>{item.BranchName}</option>
                  ))
                // :<option value={props.EmpBranch}>{props.EmpBr}</option>
                }
        </select>
        </div> */}

        {
        loading?<>
        <div style={{width:"100%", height:100, textAlign:'center'}}>
          <div class="lds-hourglass"></div>
        </div>
        
        </>:<>

        <table style={{width:"100%",marginTop:20}}>
        <tr style={{backgroundColor:"#322869",color:'white',fontSize:12}}>
            <th style={{padding:5}}>S.no.</th>
            <th>ID</th>
            <th>Authorised person</th>
            <th>Enrollment Date</th>
            {/* <th>Type</th> */}
            <th>Mobile No.</th>
            <th>GST</th>
            <th>Info</th>
        </tr>


          {Vdata.map((item , index)=>(
            <tr key={index} style={{fontSize:12,backgroundColor:color(index)}}>
              <td style={{padding:5}}>{index + 1}</td>
              <td>{item.Code}</td>
              <td>{item.Fname}</td>
              <td>{item.Receiptdate}</td>
              {/* <td>{item.FirmType}</td> */}
              <td>{item.Other}</td>
              <td>{item.GST}</td>
              {/* <td>{item.MobileNo}</td> */}
              {/* <td><i onClick={()=>{
                setEdit(true);
                setfname(item.Fname);
                settitle(item.Title);
                setbranch(item.BranchName);
                setcode(item.Code);
                setreceiptdate(item.Receiptdate);
                setvendortype(item.VendorType);
                setlname(item.Lname);
                setdesignation(item.Designation);
                setfirmname(item.FirmName);
                setfirmtype(item.FirmType);
                setfirmReg(item.FirmReg);
                setSpermanentadd(item.SPermanentAdd);
                setScity(item.SCity);
                setistate(item.Istate);
                setdistrict(item.District);
                setSpincode(item.SPinCode);
                setsameadd(item.SameAdd);
                setSSresidanceadd(item.SSResidanceAdd);
                setSScity(item.SSCity);
                setSSistate(item.SSIstate);
                setSSdistrict(item.SSDistrict);
                setpan(item.PAN);
                setgst(item.GST);
                setother(item.Other);
                setacnumber(item.AcNumber);
                setifsc(item.Ifsc);
                setbank(item.Bank);
                setbranchnm(item.Branch);
              }} className="fa-solid fa-circle-info"></i></td> */}
              <td><i onClick={()=>navigate(`/Vendor_info/${item.Code}`)} className="fa-solid fa-circle-info"></i></td>
            </tr>
          ))
          
        }
    
       

    </table>

    </>}

    </div>
    </div>
    </>
  )

}

    
}

export default VendorList

function DivInput(props){

  return (
      <div style={{padding:5, textAlign:"end",width:300}}>
      <label style={{fontSize:12,margin:"0px 15px 0px 0px"}}>{props.title}</label>
      <input value={props.value} onChange={props.getdata} placeholder={props.place} style={{fontSize:12,padding:"3px 10px 5px 10px"}} type={props.type}/>
      </div>
)}
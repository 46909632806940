import React, { useEffect, useState } from 'react'
import { MDBBtn, MDBIcon, MDBInput } from 'mdb-react-ui-kit';

import {  getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, update,push,child,get} from "firebase/database";
import {app} from "./api.js";
import {useNavigate} from 'react-router-dom'
import { MyNav } from './MyNav.js';


const db = getDatabase(app);
const dbRef = ref(getDatabase(app));
const auth = getAuth(app);

const RequestAc = () => {

    const [list, setlist] = useState([]);

    const navi = useNavigate();
    useEffect(() => {
      onAuthStateChanged(auth, user =>{
        if(user){

          const em =  user.email
      
          
          get(child(dbRef, `adminemail/`)).then((snapshot) => {
            if (snapshot.exists()) {
              const radharani = snapshot.val()
              if (radharani.email===em) {
               
              }else{
                navi('/')
  
                }
            } else {
              navi('/')
            }
          }).catch((error) => {
            navi('/')
          });
        }else{
          navi('/')
        }
      });
    }, [])

    const [reff, setreff] = useState(false);

    const qdata = ()=>{
      get(child(dbRef, `auth/`)).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(Object.values(snapshot.val()));
        setlist(Object.values(snapshot.val()))
        
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }

useEffect(() => {
  qdata()
}, [])



  return (
    <>
    <MyNav/>
    <div>
      {list.map((item, index) => (
  <div style={{display:'flex', padding:5,margin:5,backgroundColor:'#EDEDED',textAlign:'start',justifyItems:'center',justifyContent:'center'}}>
    <p style={{width:'60%'}}>{item.email}</p>

    <select style={{width:100,padding:5,marginLeft:10}} value={item.status} onChange={(e)=>update(ref(db, 'auth/'+ item.id), {
            status: e.target.value
          }).then(()=>qdata())}>
        <option value="true">Active</option>
        <option value="false">Deactivate</option>
    </select>
 
    
  </div>
))}
    </div>
    </>
  )
}

export default RequestAc
